.card {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 1.25rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    .card-header {
        background-color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        .card-title {
            margin-bottom: 0.5rem;
        }
        .card-subtitle {
            margin-top: -0.25rem;
            margin-bottom: 0;
        }
    }
    .card-body {
        padding: 1.25rem;
        .card-text:last-child {
            margin-bottom: 0;
        }
    }
}