.main {
    .lotFilters {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-evenly;
    }
    .lots {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .lot {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }
    }
}