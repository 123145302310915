.form {
    input, select, textarea {
        
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 10px;
        font-size: 16px;
        color: #333;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
        &:focus {
            border-color: #333;
        }
        
    }
    input[type=checkbox] {
        width: auto;
        margin-right: 10px;
    }
}