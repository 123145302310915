.page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    .dispatchInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .dispatchInfoItems{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .dispatchInfoItem{
                display: flex;
                flex-direction: row;
                gap: 1rem;
                .dispatchInfoItemLabel{
                    font-weight: bold;
                }
                .dispatchInfoItemValue{
                    font-weight: normal;
                }
            }
        }
    }
}