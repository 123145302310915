body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

thead {
  font-weight: 900;
}

.tableError {
  color: red !important;
  font-weight: 700 !important;
}
@import "scss/variables";
@import "scss/form";
@import "scss/alert";
@import "scss/card";
@import "scss/btn";