button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@mixin button-bg($bg, $fg: white) {
    background: $bg;
    &:hover {
      background:darken($bg,8%);
      transition: all 0.3s ease;
    }
    &:active {
      background:darken($bg,25%);
    } 
    color: $fg;
}

@mixin button-outline($bg) {
    background: white;
    border: 1px solid $bg;
    color: $bg;
    &:hover {
      background: #efefef;
      transition: all 0.3s ease;
    }
    &:active {
      background: darken($bg,25%);
    } 
}

  
.btn {
   display: inline-block;
text-decoration:none;
padding:8px 10px;
border-radius:0;
font-weight: 300;
}

.btn-green {
   @include button-bg(#445a3e);
}

.btn-gold {
   @include button-bg(#f2a900, black);
}

.btn-yellow{
   @include button-bg(#f1c40f);
}

.btn-red {
   @include button-bg(#f50013);
}
.btn-large {

   padding: 10px 30px;
   font-size: 1.5em;
}
.btn-round {
   border-radius: 50px;
}

.btn-green-outline {
   @include button-outline(#445a3e);
}
.btn-grey {
    @include button-bg(#c0c0c0);
}
.btn-grey-outline {
    @include button-outline(#c0c0c0);
}
  
.btn-black {
    @include button-bg(#000);
}
.btn-black-outline {
    @include button-outline(#000);
}

.btn-primary {
    @include button-bg(#3bd6ff);
    color:#000;
    font-weight: 700;
}
  