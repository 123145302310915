.page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .lotsInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .lot{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border-bottom: 1px solid #000;
            .lotInfo{
                display: flex;
                flex-direction: column;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
                gap: 1rem;
                .lotNoLabel, .lotTitleLabel {
                    font-weight: bold;
                    margin-right: 0.5rem;
                }
            }
            .lotDetails {
                display:grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                @media screen and (min-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }
                @media screen and (min-width: 1024px) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
                    
                
                .label {
                    font-weight: bold;
                }
                .value {
                    font-weight: normal;
                }
            }
        }
    }
    .lotImages {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .lotImage {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width:100px;
            img {
                width:100%;
            }
            .lotImageTitle {
                font-weight: bold;
            }
            .lotImageValue {
                font-weight: normal;
            }
        }
    }
}